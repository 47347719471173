// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.dev.np.isccloud.io';
const STAGE = 'dev';
const ICCA_STAGE = 'portal-dev';
const ICCA_ACCOUNT = 'workload-nonprod-ircl';

export const environment = {
  production: true, //either true or false
  appcues:true,
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_KXlAFYomF',
  NG_APP_COGNITO_CLIENT_ID: '572bpg6r118r61tlljv8r6b6pp',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  ICCA_STAGE: `${ICCA_STAGE}`,
  ICCA_ACCOUNT: `${ICCA_ACCOUNT}`,
  ICCA_REGIONS: ['us-east-1'],
  HIDDEN_OFFERS: ['doc', 'sds', 'fhirserver'],
  // OFFER_API: {
  //   sds: 'https://mx06snt1ql.execute-api.us-east-1.amazonaws.com/dev',
  // },
};
